export const enum UserflowId {
	EventsRow = 'calendar-events',
	AccommodationRow = 'calendar-accommodation-row',
	RoomTypeRow = 'room-type-row',
	PublishedPriceRecap = 'published-price-recap',
	BasePriceRecap = 'base-price-recap',
	BasePriceCopy = 'starting_price_copy_in_btn',
	JustifiersPriceRecap = 'justifiers-price-recap',
	JustifiersDetailPriceRecap = 'justifiers-detail-price-recap',
	OccupationAdrCards = 'occupation-adr-cards',
	CollapsablePriceModifiers = 'collapsable-price-modifiers',
	CollapsablePriceModifiersDetails = 'collapsable-price-modifiers-details',
	CollapsablePriceModifiersLinkedPrice = 'collapsable-price-modifiers-linked-price',
	CollapsablePriceModifiersSingleModifier = 'collapsable-price-modifiers-single-modifier',
	CloseDrawer = 'close-drawer',
	RoomTypeHeader = 'room-type-header',
	RoomTypeList = 'room-type-list',
	CalendarModifierRow = 'calendar-modifier-row',
	CalendarModifierCentralCell = 'calendar-modifier-central-cell',
	CalendarCustomizePrices = 'calendar-customizePrices',
	CalendarSubmenuButton = 'calendar-submenu-button',
	CalendarSubmenuWrapper = 'calendar-submenu-wrapper',
	BulkUpdateTypeSelector = 'bulk-update-type-selector',
	BulkUpdateRangeSelector = 'bulk-update-range-selector',
	DaysOfTheWeekSelector = 'days-of-the-week-selector',
	ModalClose = 'modal-close',
	CalendarQuotations = 'calendar-quotations',
	QuotationRangeSelector = 'quotation-range-selector',
	AccommodationDropdown = 'accommodation-dropdown',
	RoomTypeDropdown = 'room-type-dropdown',
	CalendarFocusModeSelector = 'focus-mode-select',

	AccommodationsDropdown = 'accommodations-dropdown',
	StrategiesWrapper = 'strategies-wrapper',
	BaseStrategyComponent = 'base-strategy-component',
	ParametersWrapper = 'parameters-wrapper',
	PeriodWrapper = 'period-wrapper',
	IncreasingPriceWrapper = 'increasing-price-wrapper',
	IncreasingPriceSlider = 'increasing-price-slider',
	IncreasingPriceSliderValueWrapper = 'increasing-price-slider-value-wrapper',
	DecreasingPriceWrapper = 'decreasing-price-wrapper',
	DecreasingPriceSlider = 'decreasing-price-slider',
	DecreasingPriceSliderValueWrapper = 'decreasing-price-slider-value-wrapper',
	AveragePriceWrapper = 'average-price-wrapper',
	AveragePriceSlider = 'average-price-slider',
	AveragePriceSliderValueWrapper = 'average-price-slider-value-wrapper',
	NewPeriodWrapper = 'new-period-wrapper',
	DatePickerWrapper = 'date-picker-wrapper',
	PreviewButtonWrapper = 'preview-button-wrapper',
	SaveButtonWrapper = 'save-button-wrapper',
	OccupancyCard = 'occupancy-card',
	AccommodationDetailsCharts = 'accommodation-details-charts',
	DayFilter = 'day-filter',
	Drawer = 'drawer',
	PriceCalculationResult = 'price-calculation-result',
	MarketOccupancySwitchContainer = 'market-occupancy-switch-container',
	MarketOccupancySwitch = 'market-occupancy-switch',
	MarketOccupancyRow = 'market-occupancy-row',
	BillingInfoWrapper = 'billing-info-wrapper',
	PaymentMethodsWrapper = 'payment-methods-wrapper',
	BillingInfoEditButton = 'billing-info-edit-button',
	BillingProfileItem = 'billing-profile-item',
	BillingSubscriptionItem = 'billing-subscription-item',
	BillingPlanWrapper = 'billing-plan-wrapper',
	BillingRecentInvoicesTable = 'billing-recent-invoices-table',
	BillingAllInvoicesTable = 'billing-all-invoices-table',
	AutoonboardingRoomTypeMappingLeftPanel = 'autoonboarding-room-type-mapping-left-panel',
	AutoonboardingRoomTypeMappingRightPanel = 'autoonboarding-room-type-mapping-right-panel',
	AutoonboardingRoomTypeMappingItemLeft = 'autoonboarding-room-type-mapping-item-left-',
	AutoonboardingRoomTypeMappingItemRight = 'autoonboarding-room-type-mapping-item-right-',

	// Strategy
	StrategyAccommodationsDropdown = 'strategy-accommodation-dropdown',
	StrategyUndoChangesButton = 'strategy-undo-changes-button',
	StrategySaveButton = 'strategy-save-button',
	StrategyConfigurationWrapper = 'strategy-configuration-wrapper',
	StrategyConfigurationDocumentationPanel = 'strategy-configuration-documentation-panel',
	StrategyConfigurationDefaultPeriodWrapper = 'strategy-configuration-default-period-wrapper',
	StrategyConfigurationNewPeriodButton = 'strategy-configuration-new-period-button',
	StrategyConfigurationCustomPeriodsWrapper = 'strategy-configuration-custom-periods-wrapper',
	StrategyPriceTrendsWrapper = 'strategy-price-trends-wrapper',
	StrategyConfigurationEditPeriodTitle = 'strategy-configuration-edit-period-title',
	StrategyConfigurationEditPeriodStrategySettingsInput = 'strategy-configuration-edit-period-strategy-settings-input',
	StrategyConfigurationEditPeriodStrategySettingsInputPriceDecreaseThumb = 'strategy-configuration-edit-period-strategy-settings-input-price-decrease-thumb',
	StrategyConfigurationEditPeriodStrategySettingsInputPriceIncreaseThumb = 'strategy-configuration-edit-period-strategy-settings-input-price-increase-thumb',
	StrategyConfigurationEditPeriodStartingPriceSettingsInput = 'strategy-configuration-edit-period-starting-price-settings-input',
	StrategyConfigurationEditPeriodStartingPriceSettingsInputThumb = 'strategy-configuration-edit-period-starting-price-settings-input-thumb',
	StrategyConfigurationEditPeriodMarketDemandaSettingsInput = 'strategy-configuration-edit-period-market-demand-settings-input',
	StrategyConfigurationEditPeriodMarketDemandaSettingsInputThumb = 'strategy-configuration-edit-period-market-demand-settings-input-thumb',
	StrategyConfigurationEditPeriodExampleChart = 'strategy-configuration-edit-period-example-chart',
	GeneralMaxMinModifierWrapper = 'general-max-min-modifier-wrapper',
	StrategyOpenAdvancedSettingsButton = 'open-advanced-settings-btn',
	StrategyOpenAdvancedSettingsButtonV1 = 'open-advanced-settings-btn-v1',

	StrategyAdvancedSettingsDropAndPickupWrapper = 'strategy-advanced-settings-drop-and-pickup-wrapper',
	StrategyAdvancedSettingsDropAndPickupImpactPreview = 'strategy-advanced-settings-drop-and-pickup-impact-preview',
	StrategyAdvancedSettingsDropAndPickupImpactSlider = 'strategy-advanced-settings-drop-and-pickup-impact-slider',

	StrategyAdvancedSettingsDOWWrapper = 'strategy-advanced-settings-dow-wrapper',
	StrategyAdvancedSettingsDayOfTheWeekImpactPreview = 'strategy-advanced-settings-day-of-the-week-impact-preview',
	StrategyAdvancedSettingsDayOfTheWeekInput = 'strategy-advanced-settings-day-of-the-week-input',

	StrategyAdvancedSettingsEventsWrapper = 'strategy-advanced-settings-events-wrapper',
	StrategyAdvancedSettingsEventsImpactPreview = 'strategy-advanced-settings-events-impact-preview',
	StrategyAdvancedSettingsEventsImpactSlider = 'strategy-advanced-settings-events-impact-slider',

	StrategyAdvancedSettingsStartingPricesWrapper = 'strategy-advanced-settings-starting-prices-wrapper',
	StrategyAdvancedSettingsStartingPricesPreview = 'strategy-advanced-settings-starting-prices-preview',

	StrategyAdvancedSettingsDropAndPickupOccupancySlider = 'strategy-advanced-settings-drop-and-pickup-occupancy-slider',
	StrategyAdnvacedSettingsDropAndPickupHighLowSelector = 'strategy-advanced-settings-drop-and-pickup-high-low-selector',
	StrategyAdvancedSettingsStartingPricesInput = 'strategy-advanced-settings-starting-prices-input',

	StrategyAdvancedSettingsMarketDemandInput = 'strategy-advanced-settings-market-demand-input',
	StrategyAdvancedSettingsMarketDemandPreview = 'strategy-advanced-settings-market-demand-preview',
	OrphanNightsNotAvailableBanner = 'orphan-nights-not-available-banner',
	OrphanNightsContainer = 'orphan-nights-container',

	SettingsRoomTypeListDragIcon = 'room-type-drag-component',

	BasePricesCheckboxList = 'base-prices-checkbox-list',
	BasePricesChart = 'base-prices-chart',

	// Calendar
	DraggableModifiersActionsWrapper = 'draggable-modifiers-actions-wrapper',
	DraggableModifiersPanel = 'draggable-modifiers-panel',
	DraggableModifiersSelectedCell = 'draggable-modifiers-selected-cell',
	DraggableModifiersSelectabelCell1 = 'draggable-modifiers-selectable-cell-1',
	DraggableModifiersSelectabelCell2 = 'draggable-modifiers-selectable-cell-2',

	// Dashboard
	ComparisonEditWrapper = 'comparison-edit-wrapper',
	PropertiesUnitsListWrapper = 'properties-units-list-wrapper',
	ChartsWrapper = 'charts-wrapper',
	MasterComparisonItem = 'master-comparison-item',
	ComparisonItem = 'comparison-item-',
	AddComparisonBtn = 'add-comparison-btn',
	SaveAsNewViewBtn = 'save-as-new-view-btn',
	AggregationSelector = 'aggregation-selector',

	// In-app videos
	IN_APP_VIDEOS_LAUNCHER = 'in-app-videos-launcher',

	// how does it work
	HowDoesItWorkStartingPrices = 'how-does-it-work-launcher-starting-prices',
	HowDoesItWorkLauncherPriceSync = 'how-does-it-work-launcher-price-sync',
	HowDoesItWorkLauncherOccupancyCalc = 'how-does-it-work-launcher-occupancy-calc',
	HowDoesItWorkLauncherMarketCorr = 'how-does-it-work-launcher-market-corr',

	// Accommodation settings
	MinStayAccommodation = 'min-stay-accommodation',
	MinStayAccommodationNotAvailable = 'min-stay-accommodation-not-available',
	MinStayAccommodationDefault = 'min-stay-accommodation-default',
	MinStayAccommodationEditMinStay = 'min-stay-accommodation-edit-minstay',
	MinStayAccommodationEditMinStayDow = 'min-stay-accommodation-edit-minstay-dow',
	MinStayAccommodationEditDynamic = 'min-stay-accommodation-edit-dynamic',
	MinStayAccommodationEditAdd = 'min-stay-accommodation-edit-add',
	MinStayAccommodationEditDynamicFrom = 'min-stay-accommodation-edit-dynamic-from',
	MinStayAccommodationEditDynamicTo = 'min-stay-accommodation-edit-dynamic-to',
	MinStayAccommodationEditDynamicNights = 'min-stay-accommodation-edit-dynamic-nights',
	MinStayAccommodationEditDynamicConfirm = 'min-stay-accommodation-edit-dynamic-confirm',
	MinStayAccommodationEditGap = 'min-stay-accommodation-edit-gap',
	MinStayAccommodationEditGapToggle = 'min-stay-accommodation-edit-gap-toggle',
	MinStayAccommodationEditGapNights = 'min-stay-accommodation-edit-gap-nights',
	MinStayAccommodationEditConfirm = 'min-stay-accommodation-edit-confirm',
	MinStayAccommodationCreate = 'min-stay-accommodation-create',
	MinStayRoom = 'min-stay-room',

	// Drop & pickup rules
	DropPickupRulesCount = 'drop-pickup-rules-count',
	DropPickupRulesOpenDialogButton = 'drop-pickup-rules-open-dialog-button',
	DropPickupRulesAddDaysRuleButton = 'drop-pickup-rules-add-days-rule-button',
	DropPickupRulesDaysBeforeCheckinWrapper = 'drop-pickup-rules-days-before-checkin-wrapper',
	DropPickupRulesOccupancyWrapper = 'drop-pickup-rules-occupancy-wrapper',
	DropPickupRulesAddOccupancyRuleButton = 'drop-pickup-rules-add-occupancy-rule-button',

	// Market Intelligence
	CompetitorsList = 'competitor-list',
	CompetitorsMap = 'competitors-map',
	CompetitorsStrategyPreview = 'competitors-strategy-preview',
	MarketIntelligenceResetDefaultButton = 'market-intelligence-reset-default-button',
	MarketIntelligenceAddCompetitorButton = 'market-intelligence-add-competitor-button',
	CompetitorsMarketPriceTrend = 'competitors-market-price-trend',
	MarketIntelligenceDocumentationPanel = 'market-intelligence-documentation-panel',
	MarketIntelligenceOverviewHelpContainer = 'market-intelligence-overview-help-container',
	MarketIntelligenceStrategicHelpContainer = 'market-intelligence-strategic-help-container',
	MarketIntelligenceHelpToggle = 'market-intelligence-help-toggle',
	MarketIntelligenceAccuracyContainer = 'market-intelligence-accuracy-container',
	MarketIntelligenceMarketOverviewSelector = 'market-intelligence-overview-selector',
	MarketIntelligenceStrategicOverviewSelector = 'market-intelligence-strategic-selector',
	MarketIntelligenceChartPricesSelector = 'market-intelligence-chart-prices-selector',
	MarketIntelligenceOccupancySelector = 'market-intelligence-occupancy-selector',
}
